<template>
  <main class="bg-white">
    <WidthWrapper type="header" id="article-id" class="pt-40 mb-20">
      <div class="px-20 lg:w-700">
        <div class="text-grey-450 mb-20 flex flex-wrap items-center">
          <NuxtLink :to="includedCategory?.attributes.url" class="uppercase font-bold text-14 text-red-600 pr-10">{{ includedCategory?.attributes.name }}</NuxtLink>
          <Date class="text-14" v-if="apiData.data.attributes.published" :date="apiData.data.attributes.published" :updated-date="apiData.data.attributes.displayUpdatedTime && apiData.data.attributes.changed > apiData.data.attributes.published + 60 ? apiData.data.attributes.changed : undefined" />
        </div>
        <PageTitle :title="title" />
        <div class="text-20 leading-26 font-bold mb-20" v-if="apiData.data.attributes.teaser">{{ apiData.data.attributes.teaser }}</div>
        <div class="font-normal text-14 text-black mb-20" v-if="apiData.data.attributes.authors || apiData.data.attributes.byline">
          <span>Af </span>
          <a v-if="!apiData.data.attributes.authors || apiData.data.attributes.byline" :href="`mailto:tip@seoghoer.dk?subject=Ang.%20${encodeURI(apiData.data.attributes.title)}%20(SH-${apiData.data.id})`" class="text-blue-600">{{ apiData.data.attributes.byline }}</a>
          <a v-else v-for="(author, index) in apiData.data.attributes.authors" :href="`mailto:${author.email}?subject=Ang.%20${encodeURI(apiData.data.attributes.title)}%20(SH-${apiData.data.id})`" class="text-blue-600">{{ author.name }}<span v-if="index != Object.keys(apiData.data.attributes.authors).length - 1">, </span></a>
        </div>
      </div>
      <MainMedia v-if="!!apiData.data.attributes.media" :media="apiData.data.attributes.media" :disabled="apiData.data.attributes.disableMainMedia" :page-type="apiData.data.type" :description="apiData.data.attributes.mediaDescription" :alt="apiData.data.attributes.mediaAlt" />
      <ContentGallery v-if="apiData.data.attributes.gallery" :component-content="apiData.data.attributes" class="overflow-hidden" />
    </WidthWrapper>
    <div class="relative w-full">
      <div class="px-20 lg:px-30 relative lg:flex lg:justify-between">
        <div class="lg:w-565">
          <AdWrapper v-if="apiData.data.attributes.mainMedia" class="mb-20 px-20 -mx-20" :ad="{ slotId: 'mob_responsive_2' }" grey-background />
          <AdWrapper v-if="apiData.data.attributes.mainMedia" class="mb-20" :ad="{ slotId: 'dsk_rec_ad_2' }" grey-background />
          <div class="w-full">
            <ContentComponents :content-components="apiData.data.attributes.contentComponents" class="relative text-20 leading-30" />
            <SomeShare class="mb-40" />
            <AllerVisibilityLoader v-if="apiData.data.attributes.userComments && type !== 'advertorial'">
              <AllerUserComments />
            </AllerVisibilityLoader>
          </div>
        </div>
        <div class="relative pb-20 hidden lg:block">
          <AdWrapper class="w-300 sticky top-[70px]" :ad="{ slotId: 'dsk_intext_ad_sticky1' }" />
        </div>
      </div>
    </div>
  </main>
  <div class="px-20">
    <AdWrapper :ad="{ slotId: 'dsk_intext_ad_bottom' }" grey-background />
    <AdWrapper :ad="{ slotId: 'mob_intext_ad_bottom' }" grey-background />
  </div>
  <Strossle4 widget-id="abb462d8-cb53-4cfb-816c-c25c7c581ff0" />
  <AptomaWithObserver />
</template>

<script setup lang="ts">
const apiData = inject('apiData') as Aller.Article;

const { includedCategory } = useAllerRelationships(apiData);

/**
 *
 * Decides if title has prefix in it or not
 *
 * @returns String
 */
const title = computed((): string => {
  return apiData.data.attributes.titlePrefix ? `${apiData.data.attributes.titlePrefix} ${apiData.data.attributes.title}` : apiData.data.attributes.title;
});

/**
 * Inject metatags (OG, Schema.org)
 */
useAllerMetaData().articleMeta(apiData.data, 'NewsArticle', includedCategory?.id === '16');
useAllerKilkayaData().setArticleData(apiData.data);
</script>
